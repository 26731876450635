import React, { useEffect, useState } from 'react'
import logo from '../assets/logo.png'

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [navItemsVisible, setNavItemsVisible] = useState(!isMobileView);
  const [burgerVisible, setBurgerVisible] = useState(isMobileView);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    setIsInitialLoad(false); // Mark initial load as complete after first render
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      
      if (isMobile !== isMobileView && !isTransitioning && !isInitialLoad) {
        setIsTransitioning(true);
        
        if (isMobile) {
          // Transitioning to mobile - delay burger appearance
          setNavItemsVisible(false);
          setTimeout(() => {
            setIsMobileView(true);
            setTimeout(() => {
              setBurgerVisible(true);
              setIsTransitioning(false);
            }, 300);
          }, 500);
        } else {
          // Transitioning to desktop - fade burger first
          setBurgerVisible(false);
          setTimeout(() => {
            setIsMobileView(false);
            setNavItemsVisible(true);
            setIsTransitioning(false);
          }, 800);
        }
      }
    };

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrolled(currentScrollY > 0);
      
      if (currentScrollY > lastScrollY && currentScrollY > 700) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [lastScrollY, isMobileView, isTransitioning, isInitialLoad]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header 
      className={`
        fixed top-0 left-0 right-0 z-50 
        transition-all duration-700 
        ${isScrolled ? 'bg-white' : 'bg-transparent'}
        ${isHidden ? '-translate-y-full' : 'translate-y-0'}
      `}
    >
      <div className="max-w-[1920px] mx-auto px-8 py-4 flex items-center justify-between">
        <div className="flex items-center cursor-pointer" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <img 
            src={logo} 
            alt="Logo" 
            className={`h-10 w-10 ${isScrolled ? '' : 'invert brightness-0 filter'}`} 
          />
          <span 
            className={`ml-2 text-3xl font-extralight ${isScrolled ? 'text-[#1A547E]' : 'text-white'}`} 
            style={{ fontFamily: 'Cormorant-Light' }}
          >
            MJ&A
          </span>
        </div>

        {/* Desktop Navigation with Fade Animation */}
        <nav 
          className={`md:block transition-opacity duration-500 ease-in-out ${
            isMobileView ? 'hidden' : 'block'
          }`}
        >
          <ul className="flex space-x-6">
            {['Services', 'About', 'Contact'].map((item, index) => (
              <li 
                key={item}
                className="transform transition-all duration-500"
                style={{
                  opacity: navItemsVisible ? 1 : 0,
                  transform: navItemsVisible ? 'translateX(0)' : `translateX(-${index * 20}px)`,
                  transitionDelay: `${index * 100}ms`
                }}
              >
                <a 
                  href={`#${item.toLowerCase()}`} 
                  className={`${isScrolled ? 'text-[#1A547E]' : 'text-white'} hover:text-[#2B6A9A] transition duration-300`}
                >
                  {item}
                </a>
              </li>
            ))}
            <li
              className="transform transition-all duration-500"
              style={{
                opacity: navItemsVisible ? 1 : 0,
                transform: navItemsVisible ? 'translateX(0)' : 'translateX(-60px)',
                transitionDelay: '300ms'
              }}
            >
              <a 
                href="#portal" 
                className="text-white bg-[#1A547E] hover:bg-[#2B6A9A] transition duration-300 px-3 py-1.5 rounded text-sm"
              >
                Log In
              </a>
            </li>
          </ul>
        </nav>

        {/* Hamburger Menu Button with Slower Fade Animation */}
        <div 
          className={`transition-all duration-800 ease-in-out ${
            !isMobileView ? 'hidden md:hidden' : 'block'
          }`}
          style={{
            opacity: burgerVisible ? 1 : 0,
            transform: burgerVisible ? 'translateX(0)' : 'translateX(20px)',
            pointerEvents: burgerVisible ? 'auto' : 'none',
            transition: 'opacity 800ms ease-in-out, transform 800ms ease-in-out'
          }}
        >
          <button 
            className="flex flex-col justify-center items-center w-6 h-6 space-y-1.5"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <span 
              className={`w-6 h-0.5 transition-all duration-300 ${
                isMenuOpen ? 'transform rotate-45 translate-y-2' : ''
              } ${isScrolled ? 'bg-[#1A547E]' : 'bg-white'}`}
            />
            <span 
              className={`w-6 h-0.5 transition-all duration-300 ${
                isMenuOpen ? 'opacity-0' : 'opacity-100'
              } ${isScrolled ? 'bg-[#1A547E]' : 'bg-white'}`}
            />
            <span 
              className={`w-6 h-0.5 transition-all duration-300 ${
                isMenuOpen ? 'transform -rotate-45 -translate-y-2' : ''
              } ${isScrolled ? 'bg-[#1A547E]' : 'bg-white'}`}
            />
          </button>

          {/* Mobile Dropdown Menu */}
          <div 
            className={`absolute right-0 top-12 w-48 bg-white shadow-lg rounded-lg transition-all duration-300 ease-in-out ${
              isMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'
            } overflow-hidden`}
          >
            <nav className="py-2">
              <ul className="space-y-1">
                {['Services', 'About', 'Contact'].map((item) => (
                  <li key={item}>
                    <a 
                      href={`#${item.toLowerCase()}`} 
                      className="block px-4 py-2 text-[#1A547E] hover:bg-gray-50 hover:text-[#2B6A9A] transition duration-300" 
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {item}
                    </a>
                  </li>
                ))}
                <li className="px-4 py-2">
                  <a 
                    href="#portal" 
                    className="block text-center text-white bg-[#1A547E] hover:bg-[#2B6A9A] transition duration-300 px-3 py-1.5 rounded text-sm" 
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Log In
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;