import React, { useState, useEffect, useRef } from 'react';
import services from '../assets/a1.png';
import services1 from '../assets/bone3.png';

interface ServiceLine {
  text: string;
  ref: React.RefObject<HTMLLIElement>;
}

const Services = () => {
  const [currentImage, setCurrentImage] = useState(services1);
  const coreServicesTitleRef = useRef<HTMLDivElement>(null);
  const coreServicesTitleLineRef = useRef<HTMLDivElement>(null);
  const advancedServicesTitleRef = useRef<HTMLDivElement>(null);
  const advancedServicesTitleLineRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);

  // Create refs for each service line
  const coreServicesLeft: ServiceLine[] = [
    'Lameness exams',
    'Nerve/joint blocks', 
    'Joint injections',
    'Poor performance evaluations',
    'Endoscopy',
    'Digital radiography',
    'Ultrasound',
    'Exercising endoscopy',
    'Foal leg assessments'
  ].map(text => ({ text, ref: useRef<HTMLLIElement>(null) }));

  const coreServicesRight: ServiceLine[] = [
    'Skin tumours/sarcoid treatment',
    'Standing fracture repair',
    'Epiglottic entrapment surgery',
    'Yearling radiography',
    'Castrations',
    'Laceration repair',
    'Periosteal elevations',
    'Transphyseal screws'
  ].map(text => ({ text, ref: useRef<HTMLLIElement>(null) }));

  const advancedServicesLeft: ServiceLine[] = [
    'Arthroscopy',
    'Fracture repair',
    'Cryptorchid/rig castration',
    'Reproductive surgical procedures',
    'Splint bone removals'
  ].map(text => ({ text, ref: useRef<HTMLLIElement>(null) }));

  const advancedServicesRight: ServiceLine[] = [
    'Tie backs (standing)',
    'Tie forwards',
    'Transphyseal screws',
    'Urethral extensions',
    'Endoscopic diode laser surgery'
  ].map(text => ({ text, ref: useRef<HTMLLIElement>(null) }));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => 
        prevImage === services1 ? services : services1
      );
    }, 10000);

    const observerOptions: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          if (entry.target === coreServicesTitleLineRef.current || 
              entry.target === advancedServicesTitleLineRef.current) {
            entry.target.classList.add('animate-draw-line');
          } else {
            entry.target.classList.add('animate-fade-in');
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    // Observe all refs
    [
      coreServicesTitleRef,
      coreServicesTitleLineRef,
      advancedServicesTitleRef,
      advancedServicesTitleLineRef,
      descriptionRef
    ].forEach(ref => {
      if (ref.current) observer.observe(ref.current);
    });

    [...coreServicesLeft, ...coreServicesRight, ...advancedServicesLeft, ...advancedServicesRight]
      .forEach(({ ref }) => {
        if (ref.current) observer.observe(ref.current);
      });

    return () => {
      clearInterval(interval);
      observer.disconnect();
    };
  }, []);

  return (
    <section id="services" className="relative overflow-hidden bg-gray-100">
      <div className="flex flex-col md:flex-row w-full">
        <div className="w-full md:w-1/2 px-10 py-14">
          {/* Core Services Section */}
          <div className="relative mb-12">
            <div 
              ref={coreServicesTitleRef}
              className="text-center mb-8 opacity-0 transition-all duration-700 translate-y-4"
            >
              <h3 className="text-3xl text-[#1A547E] tracking-widest" style={{ fontFamily: 'Cormorant-Light' }}>
                CORE SERVICES
              </h3>
              <div 
                ref={coreServicesTitleLineRef}
                className="w-24 h-px bg-[#1A547E] mx-auto mt-2 origin-left"
                style={{
                  transform: 'scaleX(0)',
                  transition: 'transform 0.8s cubic-bezier(0.85, 0, 0.15, 1)',
                  transitionDelay: '200ms'
                }}
              ></div>
            </div>
            
            <div className="grid grid-cols-2 gap-4 mb-8">
              <ul className="pl-4 space-y-2">
                {coreServicesLeft.map((service, index) => (
                  <li
                    key={service.text}
                    ref={service.ref}
                    className={`relative pl-5 opacity-0 transition-all duration-500 translate-y-2`}
                    style={{ 
                      fontFamily: 'Cormorant-Light', 
                      fontSize: '18px',
                      transitionDelay: `${100 * index}ms`,
                    }}
                  >
                    <span className="absolute left-0 text-[#1A547E] text-xl">•</span>
                    {service.text}
                  </li>
                ))}
              </ul>
              <ul className="pl-4 space-y-2">
                {coreServicesRight.map((service, index) => (
                  <li
                    key={service.text}
                    ref={service.ref}
                    className={`relative pl-5 opacity-0 transition-all duration-500 translate-y-2`}
                    style={{ 
                      fontFamily: 'Cormorant-Light', 
                      fontSize: '18px',
                      transitionDelay: `${100 * index}ms`,
                    }}
                  >
                    <span className="absolute left-0 text-[#1A547E] text-xl">•</span>
                    {service.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Advanced Services Section */}
          <div className="relative">
            <div 
              ref={advancedServicesTitleRef}
              className="text-center mb-8 opacity-0 transition-all duration-700 translate-y-4"
            >
              <h3 className="text-3xl text-[#1A547E] tracking-widest" style={{ fontFamily: 'Cormorant-Light' }}>
                ADVANCED PROCEDURES
              </h3>
              <div 
                ref={advancedServicesTitleLineRef}
                className="w-24 h-px bg-[#1A547E] mx-auto mt-2 origin-left"
                style={{
                  transform: 'scaleX(0)',
                  transition: 'transform 0.8s cubic-bezier(0.85, 0, 0.15, 1)',
                  transitionDelay: '200ms'
                }}
              ></div>
            </div>
            
            <p 
              ref={descriptionRef}
              className="mb-6 text-gray-600 text-center italic opacity-0 transition-all duration-500 translate-y-2" 
              style={{ fontFamily: 'Cormorant-Light', fontSize: '18px' }}
            >
              Complex surgical procedures requiring anaesthesia performed at Agnes Banks Equine Clinic
            </p>

            <div className="grid grid-cols-2 gap-4">
              <ul className="pl-4 space-y-2">
                {advancedServicesLeft.map((service, index) => (
                  <li
                    key={service.text}
                    ref={service.ref}
                    className={`relative pl-5 opacity-0 transition-all duration-500 translate-y-2`}
                    style={{ 
                      fontFamily: 'Cormorant-Light', 
                      fontSize: '18px',
                      transitionDelay: `${100 * index}ms`,
                    }}
                  >
                    <span className="absolute left-0 text-[#1A547E] text-xl">•</span>
                    {service.text}
                  </li>
                ))}
              </ul>
              <ul className="pl-4 space-y-2">
                {advancedServicesRight.map((service, index) => (
                  <li
                    key={service.text}
                    ref={service.ref}
                    className={`relative pl-5 opacity-0 transition-all duration-500 translate-y-2`}
                    style={{ 
                      fontFamily: 'Cormorant-Light', 
                      fontSize: '18px',
                      transitionDelay: `${100 * index}ms`,
                    }}
                  >
                    <span className="absolute left-0 text-[#1A547E] text-xl">•</span>
                    {service.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Right Half - Images */}
        <div className="relative w-full md:w-1/2 h-[50vh] md:h-full md:absolute md:right-0 md:top-0 md:bottom-0">
          <div className="relative w-full h-full">
            <img 
              src={services} 
              alt="Services"
              className="absolute inset-0 object-cover h-full w-full transition-opacity duration-[3000ms] ease-in-out"
              style={{ opacity: currentImage === services ? 1 : 0 }}
            />
            <img 
              src={services1} 
              alt="Services 1"
              className="absolute inset-0 object-cover h-full w-full transition-opacity duration-[3000ms] ease-in-out"
              style={{ opacity: currentImage === services1 ? 1 : 0 }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;