import React, { useState, useRef, useEffect } from 'react'
import { Phone, Mail, MapPin, LucideIcon } from 'lucide-react'
import horse from '../assets/horse1.png'
import makeRequest from '../services/api'

interface ContactInfoProps {
  icon: LucideIcon;
  title: string;
  content: string;
}

interface FormData {
  name: string;
  email: string;
  message: string;
}

const ContactInfo: React.FC<ContactInfoProps> = ({ icon: Icon, title, content }) => (
  <div className="flex items-center mb-4">
    <Icon className="h-6 w-6 text-indigo-600 mr-2" />
    <div>
      <h3 className="font-semibold">{title}</h3>
      <p>{content}</p>
    </div>
  </div>
)

const Contact = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  // Refs for animations
  const titleLetterRefs = Array.from({ length: 7 }, () => useRef<HTMLDivElement>(null));
  const titleLineRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const nameFieldRef = useRef<HTMLDivElement>(null);
  const emailFieldRef = useRef<HTMLDivElement>(null);
  const messageFieldRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const observerOptions: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          if (entry.target === titleLineRef.current) {
            entry.target.classList.add('animate-draw-line');
          } else {
            (entry.target as HTMLElement).classList.add('animate-fade-in');
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    // Observe all refs
    [
      ...titleLetterRefs,
      titleLineRef,
      formRef,
      nameFieldRef,
      emailFieldRef,
      messageFieldRef,
      buttonRef
    ].forEach(ref => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => observer.disconnect();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData(prevData => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await makeRequest<{ message: string }>(
        'POST',
        '/submit_request',
        {
          name: formData.name,
          email: formData.email,
          message: formData.message
        }
      );
      setSubmitMessage(response.message);
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting request:', error);
      setSubmitMessage('An error occurred while submitting your request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact" className="py-6 bg-gray-100">
      {/* Title Section */}
      <div className="container mx-auto px-4">
        <div className="w-full mb-12 flex justify-center">
          <div className="relative overflow-hidden mt-8">
            <div className="flex justify-center">
              {['C', 'O', 'N', 'T', 'A', 'C', 'T'].map((letter, index) => (
                <div 
                  key={index}
                  ref={titleLetterRefs[index]}
                  className="w-12 h-24 flex items-center justify-center text-[#1A547E] text-3xl font-light opacity-0 transition-all duration-500 translate-y-4"
                  style={{ 
                    fontFamily: 'Cormorant-Light',
                    transitionDelay: `${index * 100}ms`
                  }}
                >
                  {letter}
                </div>
              ))}
            </div>
            <div 
              ref={titleLineRef}
              className="w-24 h-px bg-[#1A547E] mx-auto mt-[-19px] origin-left"
              style={{
                transform: 'scaleX(0)',
                transition: 'transform 0.8s cubic-bezier(0.85, 0, 0.15, 1)',
                transitionDelay: '800ms'
              }}
            ></div>
          </div>
        </div>
      </div>

      {/* Form Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <div className="flex justify-center">
          <div className="w-full max-w-2xl">
            <form 
              ref={formRef}
              onSubmit={handleSubmit} 
              className="bg-white p-8 rounded shadow-md opacity-0 transition-all duration-700 translate-y-4"
              style={{ transitionDelay: '900ms' }}
            >
              <div 
                ref={nameFieldRef}
                className="mb-6 opacity-0 transition-all duration-500 translate-y-4"
                style={{ transitionDelay: '1000ms' }}
              >
                <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">Name</label>
                <input 
                  type="text" 
                  id="name" 
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1A547E]" 
                  required 
                />
              </div>
              <div 
                ref={emailFieldRef}
                className="mb-6 opacity-0 transition-all duration-500 translate-y-4"
                style={{ transitionDelay: '1100ms' }}
              >
                <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1A547E]" 
                  required 
                />
              </div>
              <div 
                ref={messageFieldRef}
                className="mb-6 opacity-0 transition-all duration-500 translate-y-4"
                style={{ transitionDelay: '1200ms' }}
              >
                <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">Message</label>
                <textarea 
                  id="message" 
                  rows={4} 
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#1A547E]" 
                  required
                ></textarea>
              </div>
              <button 
                ref={buttonRef}
                type="submit" 
                disabled={isSubmitting}
                className="w-50 text-white bg-[#1A547E] hover:bg-[#2B6A9A] transition duration-300 px-4 py-3 rounded-md text-sm font-semibold opacity-0 transition-all duration-500 translate-y-4"
                style={{ transitionDelay: '1300ms' }}
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </button>
              {submitMessage && (
                <div className="mt-4 text-center text-green-600 font-semibold">{submitMessage}</div>
              )}
            </form>
          </div>
        </div>
        <div className="h-16"></div>
      </div>
    </section>
  );
};

export default Contact;

