import React from 'react';

const Portal = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center px-4">
      <div className="text-center max-w-2xl mx-auto">
        <h1 
          className="text-4xl md:text-5xl text-[#1A547E] mb-6"
          style={{ fontFamily: 'Cormorant-Light' }}
        >
          Portal Access Coming Soon
        </h1>
        <div 
          className="w-24 h-px bg-[#1A547E] mx-auto mb-8"
        />
        <p 
          className="text-xl md:text-2xl text-gray-600 mb-8"
          style={{ fontFamily: 'Cormorant-Light' }}
        >
          We're currently working on building a better experience for our clients.
        </p>
        <button 
          onClick={() => window.location.href = '/'} 
          className="text-white bg-[#1A547E] hover:bg-[#2B6A9A] transition duration-300 px-6 py-3 rounded text-base"
        >
          Return Home
        </button>
      </div>
    </div>
  );
};

export default Portal;