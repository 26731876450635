import React, { useState } from 'react'
import Header from './components/Header'
import Hero from './components/Hero'
import Services from './components/Services'
import About from './components/About'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Portal from './components/Portal'


const App = () => {
  const [currentHash, setCurrentHash] = useState(window.location.hash);

  // Listen for hash changes
  window.addEventListener('hashchange', () => {
    setCurrentHash(window.location.hash);
  });

  // Render based on current hash
  if (currentHash === '#portal') {
    return <Portal />;
  }

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Header />
      <Hero />
      <Services />
      <About />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;