import React, { useEffect, useRef } from 'react';
import mj from '../assets/mj1.png';
import a2 from '../assets/a2.png';

const About = () => {
  // Refs for all animated elements
  const titleLetterRefs = Array.from({ length: 5 }, () => useRef<HTMLDivElement>(null));
  const titleLineRef = useRef<HTMLDivElement>(null);
  
  // First doctor section refs
  const alannahImageRef = useRef<HTMLDivElement>(null);
  const alannahTitleRef = useRef<HTMLHeadingElement>(null);
  const alannahPara1Ref = useRef<HTMLParagraphElement>(null);
  const alannahPara2Ref = useRef<HTMLParagraphElement>(null);
  
  // Second doctor section refs
  const mjImageRef = useRef<HTMLDivElement>(null);
  const mjTitleRef = useRef<HTMLHeadingElement>(null);
  const mjPara1Ref = useRef<HTMLParagraphElement>(null);
  const mjPara2Ref = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const observerOptions: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          if (entry.target === titleLineRef.current) {
            entry.target.classList.add('animate-draw-line');
          } else {
            entry.target.classList.add('animate-fade-in');
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    // Observe all refs
    [
      ...titleLetterRefs,
      titleLineRef,
      alannahImageRef,
      alannahTitleRef,
      alannahPara1Ref,
      alannahPara2Ref,
      mjImageRef,
      mjTitleRef,
      mjPara1Ref,
      mjPara2Ref
    ].forEach(ref => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section id="about" className="py-10 px-6 bg-white relative overflow-hidden">
      <div className="w-full px-4 sm:px-6 md:px-8 max-w-[1400px] mx-auto">
        <div className="mb-12 flex justify-center">
          <div className="w-full max-w-md relative overflow-hidden">
            <div className="flex justify-center">
              {['A', 'B', 'O', 'U', 'T'].map((letter, index) => (
                <div 
                  key={index}
                  ref={titleLetterRefs[index]}
                  className="w-12 h-24 flex items-center justify-center text-[#1A547E] text-3xl font-light opacity-0 transition-all duration-500 translate-y-4"
                  style={{ 
                    fontFamily: 'Cormorant-Light',
                    transitionDelay: `${index * 100}ms`
                  }}
                >
                  {letter}
                </div>
              ))}
            </div>
            <div 
              ref={titleLineRef}
              className="w-24 h-px bg-[#1A547E] mx-auto mt-[-19px] origin-left"
              style={{
                transform: 'scaleX(0)',
                transition: 'transform 0.8s cubic-bezier(0.85, 0, 0.15, 1)',
                transitionDelay: '600ms'
              }}
            ></div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center mb-12 gap-8">
          <div 
            ref={alannahImageRef}
            className="w-full md:w-1/2 opacity-0 transition-all duration-700 translate-x-8"
          >
            <img src={a2} alt="Dr. Alannah Norton" className="rounded-lg shadow-lg w-full h-auto" />
          </div>
          <div className="w-full md:w-1/2 md:pl-8">
            <h3 
              ref={alannahTitleRef}
              className="text-2xl font-light mb-3 text-[#1A547E] opacity-0 transition-all duration-500 translate-y-4"
              style={{ fontFamily: 'Cormorant-Light', transitionDelay: '200ms' }}
            >
              Dr. Alannah Norton
            </h3>
            <p 
              ref={alannahPara1Ref}
              className="text-gray-600 mb-8 text-justify opacity-0 transition-all duration-500 translate-y-4"
              style={{ fontFamily: 'Cormorant-Light', fontSize: '20px', lineHeight: '1.6', transitionDelay: '400ms' }}
            >
              Dr. Alannah Norton grew up in Warwickshire, England, and earned her Bachelor of Veterinary Medicine from the University of Glasgow, Scotland, in 2015. Following her graduation, she gained international experience with positions in Morocco and Ireland. She then undertook an 18-month internship at Goulburn Valley Equine Hospital in Shepparton, Victoria, where she also completed a three-year surgical residency.
            </p>
            <p 
              ref={alannahPara2Ref}
              className="text-gray-600 mb-4 text-justify opacity-0 transition-all duration-500 translate-y-4"
              style={{ fontFamily: 'Cormorant-Light', fontSize: '20px', lineHeight: '1.6', transitionDelay: '600ms' }}
            >
              She has since worked as an equine surgeon and racetrack veterinarian at Agnes Banks Equine Clinic for two years, followed by a year in a similar role at Avenel Equine Hospital. Her professional interests focus on poor performance evaluations, orthopaedic surgery, and respiratory surgery.
            </p>
          </div>
        </div>
        
        <div className="flex flex-col md:flex-row-reverse items-center gap-8">
          <div 
            ref={mjImageRef}
            className="w-full md:w-1/2 opacity-0 transition-all duration-700 translate-x-8"
          >
            <img src={mj} alt="Dr. Mary-Jane Stutsel" className="rounded-lg shadow-lg w-full h-auto" />
          </div>
          <div className="w-full md:w-1/2 md:pr-8">
            <h3 
              ref={mjTitleRef}
              className="text-2xl font-light mb-3 text-[#1A547E] opacity-0 transition-all duration-500 translate-y-4"
              style={{ fontFamily: 'Cormorant-Light', transitionDelay: '200ms' }}
            >
              Dr. Mary-Jane Stutsel
            </h3>
            <p 
              ref={mjPara1Ref}
              className="text-gray-600 mb-8 text-justify opacity-0 transition-all duration-500 translate-y-4"
              style={{ fontFamily: 'Cormorant-Light', fontSize: '20px', lineHeight: '1.6', transitionDelay: '400ms' }}
            >
              Dr. Mary-Jane Stutsel graduated from The University of Queensland in 1994 and began her career with the RSPCA in Broken Hill. She then established her own practice in Cobar and shortly after expanded to Bourke, demonstrating her commitment to providing veterinary care in rural communities.
            </p>
            <p 
              ref={mjPara2Ref}
              className="text-gray-600 mb-4 text-justify opacity-0 transition-all duration-500 translate-y-4"
              style={{ fontFamily: 'Cormorant-Light', fontSize: '20px', lineHeight: '1.6', transitionDelay: '600ms' }}
            >
              For over 20 years, she served as a solo practitioner for both towns while raising her family, regularly traveling more than 1000 km on dirt roads. Her dedication to advancing her skills led her to obtain a Master in Veterinary Studies and memberships with both the Australian and New Zealand College of Veterinary Scientists in small animal surgery. After relocating to Sydney to further her children's education, she continued to develop her expertise, achieving ANZCVS Membership in equine surgery in 2023. In 2008, she was recognized among the top 100 women awarded the Belle Bruce Reid Medal for her outstanding contributions as a female veterinary science graduate, commemorating Australia's first woman veterinarian from 1906.
            </p>
          </div>
          <div className="h-16"></div>
        </div>
        <div className="h-16"></div>
      </div>
    </section>
  );
};

export default About;

