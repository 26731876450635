import React from 'react'
import Slider from 'react-slick'
import type { Settings } from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import background from '../assets/background1.png'
import riding from '../assets/riding1.png'
import riding2 from '../assets/riding2.png'

const Hero = () => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    fade: true,
    cssEase: 'linear',
    adaptiveHeight: false
  }

  const images = [
    background,
    riding,
    riding2,
    "https://images.unsplash.com/photo-1553284965-83fd3e82fa5a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
  ]

  return (
    <section className="relative h-screen w-full overflow-hidden">
      <style>{`
        .hero-slide {
          transform: scale(1.1);
        }
        
        .slick-slider, .slick-list, .slick-track {
          height: 100%;
        }

        .slick-slide > div {
          height: 100%;
        }

        .slick-slider img {
          transition: transform 0.3s ease-out;
        }

        @media (min-width: 640px) {
          .hero-slide {
            transform: scale(1.15);
          }
        }
      `}</style>
      <div className="absolute inset-0">
        <Slider {...settings} className="!h-full">
          {images.map((image, index) => (
            <div key={index} className="relative h-full">
              <div className="absolute inset-0 hero-slide">
                <img 
                  src={image} 
                  alt={`Equine Surgery ${index + 1}`} 
                  className="w-full h-full object-cover scale-110"
                  style={{ 
                    willChange: 'transform',
                    transformOrigin: 'center center',
                    transform: 'scale(1.1)'
                  }}
                />
                <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                  <div className="text-center text-white px-4">
                    <h1 className="text-4xl md:text-6xl mb-4" style={{ fontFamily: 'Cormorant-Light' }}>
                      Expert Veterinary Services
                    </h1>
                    <p className="text-xl md:text-2xl mb-8 italic" style={{ fontFamily: 'Cormorant-Light' }}>
                      Providing top-tier surgical care for your horses
                    </p>
                    <a 
                      href="#contact" 
                      className="text-white bg-[#1A547E] hover:bg-[#2B6A9A] transition duration-300 px-3 py-3 rounded text-base"
                    >
                      Request a Consultation
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default Hero