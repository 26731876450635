import React from 'react'
import { Stethoscope } from 'lucide-react'

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-4 md:mb-0">
            <img src={require('../assets/logo.png')} alt="Logo" className="h-12 w-12" />
            <div className="text-sm text-gray-400"></div>
          </div>
          <div className="text-sm text-gray-400">
            &copy; 2024 MJ&A Veterinary Services
            <br />
            Design by Inabit Limited
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer