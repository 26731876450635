import axios, { AxiosRequestConfig } from 'axios';

const API_URL = 'https://api.inabit-limited.tech';
console.log('Backend API URL:', API_URL);

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const makeRequest = async <T>(
  method: 'GET' | 'POST',
  endpoint: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    console.log("Making a request to: ", API_URL);
    const response = await apiClient({
      method,
      url: endpoint,
      data,
      ...config,
    });
    return response.data;
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

export default makeRequest;
